import React from "react"
import Seo from "../components/seo"
import PostBlock from "../components/PostBlock"
import Pagination from "../components/pagination"
import PostsContainer from "../components/PostsContainer"
import FullWidthLayout from "../components/layouts/FullWidthLayout"
import styled from "@emotion/styled"
import { Container, Row, Col } from 'react-grid-system';
import { H1, HeadingCaption } from './../components/Headings';
import SocialIcons from './../components/Social/SocialIcons'
import Divider from "../components/Divider"


const HeroBox = styled.div`
  background: #70bdc2;
  padding: 40px;
  .content {
    max-width: 960px;
    margin: auto;
  }
`

const CircleImage = styled.img`
  border-radius: 50%;
  border: white 5px solid;
`

export default function Template(props) {
  const {
    pageContext: {
      posts,
    }
  } = props;

  return (
    <FullWidthLayout>
      <Seo title="Home"/>

      <HeroBox>
        <div className="content">
          <Container>
            <Row>
              <Col sm={4}>
                <CircleImage src="/zuk.jpeg"/>
              </Col>
              <Col sm={8}>
                <H1 inverse>I'm Tzook Bar Noy</H1>
                <HeadingCaption inverse>Software Engineer</HeadingCaption>
                <Divider size={20}/>
                <p className="inverse">
                  I love my work, health and fitness enthusiastic. Doing boxing, Jiu Jitsu and
                  wall climbing on my spare time. 10+ exp in the engineering world. Building
                  products, UIs, services and solving algorithms for fun.
                </p>
                <Divider size={20}/>
                <SocialIcons/>
              </Col>
            </Row>
          </Container>
        </div>
      </HeroBox>

      <Divider size={30}/>

      <PostsContainer>
        {posts.slice(0,12).map((node) => {
          return (
            <div key={node.meta.url}>
              <PostBlock {...node}/>
            </div>
          )
        })}
      </PostsContainer>

      <div style={{
        maxWidth: '960px',
        margin: 'auto'
      }}>
        <Pagination pages={Math.ceil((posts.length-12)/12)} cur={-1}/>
      </div>
    </FullWidthLayout>
  )
}
