import React from "react"
import styled from "@emotion/styled"
import { useTheme } from '@emotion/react'
import {
  FaLinkedinIn, FaTwitter, FaGithub, FaStackOverflow
} from 'react-icons/fa';

const IconsList = styled.ul`
  margin: 0;
  li {
    display: inline-block;
    list-style: none;
    margin-right: 20px;
  }
`

const SocialIcons = _ => {
  const theme = useTheme();
  const iconProps = {
    color: theme.inverse.colors.baseTextColor
  }
  return (
    <IconsList>
      {items.map(item => {
        const CompIcon = item.component
        return (
          <li key={item.url}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              <CompIcon {...iconProps} />
            </a>
          </li>
        );
      })}
    </IconsList>
  )
}

const items = [
  {
    url: 'https://www.linkedin.com/in/tzookbarnoy/',
    component: FaLinkedinIn,
  },
  {
    url: 'https://github.com/tzookb',
    component: FaGithub,
  },
  {
    url: 'https://twitter.com/tzookb',
    component: FaTwitter,
  },
  {
    url: 'https://stackoverflow.com/users/1033518/tzook-bar-noy?tab=profile',
    component: FaStackOverflow,
  },
]

export default SocialIcons
