import React from "react"
import styled from "@emotion/styled"
import BaseLayout from "./BaseLayout"

const BodyContInner = styled.div`
  flex: 1;
  margin: 0;
  padding: 0;
  width: 100%;
`

const FullWidthLayout = ({ children }) => {
  return (
    <BaseLayout>
      <BodyContInner>
        <main>{children}</main>
      </BodyContInner>
    </BaseLayout>
  )
}


export default FullWidthLayout
